import React, { useContext, useState } from "react"
import {
  isBlank,
  isValidEmail,
  isValidPassword,
  isSpecialChar,
} from "src/component/FormValidation"
import axios from "axios"
import ApiConfig, { facebookAppId } from "src/config/ApiConfig"
import Otp from "src/views/modal/Otp"
import {
  GlobalTextInput,
  GlobalTextInputForDate,
} from "src/component/GlobalTextInput"
import { useHistory } from "react-router-dom"
import { AuthContext } from "src/context/Auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"
import moment from "moment"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import InlineError from "src/component/InlineError"

export default function Join() {
  const history = useHistory()
  const auth = useContext(AuthContext)
  const [isDate, setIsDate] = useState(false)
  const [formValue, setFormValue] = useState({
    email: "",
    password: "",
    company: "",
    mobile: "",
    fullname: "",
    code: "",
    countryCode: "",
    dateOfBirth: "",
    companyName: "",
  })
  const [submit, setSubmit] = useState(false)
  const [isSubmiting, setIsSubmiting] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState("")
  const [signInToken, setSignInToken] = useState("")
  const [selectedCode, setSelectedCode] = useState("")
  const [contactNumber, setContactNumber] = useState("")
  const [contactNumberWithCode, setContactNumberWithCode] = useState("")
  const responseFacebook = (response) => {
    if (response.name) {
      var name = response.name.split(" ")
      let body = {
        socialId: response.id,
        socialType: "facebook",
        email: response.email,
        firstName: name[0],
        lastName: name[1],
        socialImage: response.picture.data.url,
      }
      socialLogin(body)
    }
  }

  const socialLogin = async (body) => {
    const options = {
      method: "POST",
      data: body,
      url: ApiConfig.socialLogin,
    }
    console.log("options--->", options)

    const res = await axios(options)
    console.log("socialLogin--->", res)

    if (res.data.responseCode !== 200) {
      setError(res.data.responseMessage)
    } else {
      // window.$('#otp').modal('show');
      if (res.data.result.otpVerification === false) {
        window.$("#otp").modal("show")
        window.$("#login").modal("hide")
        const temp = { ...formValue, mobile: res.data.result.mobileNumber }
        setFormValue(temp)
      } else {
        console.log(res.data.result)
        auth.userLogIn(true, res.data.result.token)
        window.$("#join").modal("hide")
        setSubmit(false)
      }
    }
    setIsSubmiting(false)
  }

  const signIn = async (e) => {
    console.log(
      "formValue.countryCode + formValue.mobile",
      formValue.countryCode + formValue.mobile
    )
    e.preventDefault()
    setSubmit(true)
    setError("")
    if (
      !isBlank(formValue.email) &&
      isValidEmail(formValue.email) &&
      !isBlank(formValue.password) &&
      isValidPassword(formValue.password) &&
      !isBlank(formValue.fullname) &&
      !isBlank(contactNumber) &&
      // isValidNumber(formValue.mobile) &&
      !isBlank(selectedCode) &&
      !isSpecialChar(formValue.fullname) &&
      parseInt(contactNumber) > 0
    ) {
      // window.$('#join').modal('hide');
      // window.$('#signupOtp').modal('show');
      try {
        setIsSubmiting(true)
        const res = await axios.post(ApiConfig.signup, {
          name: formValue.fullname,
          mobileNumber: contactNumber,
          countryCode: selectedCode,
          email: formValue.email,
          password: formValue.password,
          corporateId: formValue.company,
          referralCode: formValue.code,
          dateOfBirth: formValue.dateOfBirth,
        })
        console.log(res.data)
        if (res.data.responseCode !== 200) {
          setError(res.data.responseMessage)
        } else {
          window.$("#join").modal("hide")
          window.$("#signupOtp").modal("show")
          setSignInToken(res.data.result.token)
        }
        setIsSubmiting(false)
      } catch (err) {
        setError("Something went wrong!")
        setIsSubmiting(false)
      }
      setSubmit(false)
    }
  }

  const _onOtpSubmit = async (otp) => {
    setError("")
    try {
      const res = await axios.post(ApiConfig.verifyOTP, {
        email: formValue.email,
        otp,
      })
      if (res.data.responseCode !== 200) {
        setError(res.data.responseMessage)
      } else {
        console.log(res.data)
        window.$("#signupOtp").modal("hide")
        auth.userLogIn(true, signInToken)
        history.push("/appointment-address")
      }
      setIsSubmiting(false)
    } catch (err) {
      setError("Something went wrong!")
      setIsSubmiting(false)
    }
  }

  const _onInputChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    const temp = { ...formValue, [name]: value }
    setFormValue(temp)
  }

  return (
    <>
      <div
        className="modal fade"
        id="join"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered join-dialog"
          role="document"
        >
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="modal-body join-body p-0">
              {/* <form> */}
              <div className="row">
                <div className="col-lg-6 col-md-12  text-center">
                  <div className="join-col">
                    <h3>Join Alafiame with </h3>
                    <figure className="join-logo">
                      <img
                        src="/images/footer-Logo.png"
                        alt="logo"
                        style={{ height: 125, width: 225 }}
                      />
                    </figure>
                    <FacebookLogin
                      appId={facebookAppId}
                      callback={responseFacebook}
                      autoLoad={false}
                      fields="name,email,picture"
                      scope="public_profile, email"
                      render={(renderProps) => (
                        <div className="btn-fb2 mt-2">
                          {" "}
                          <button
                            className="btn btn-theam-fill mt-3 w-100"
                            onClick={renderProps.onClick}
                          >
                            <i
                              className="fa fa-facebook"
                              aria-hidden="true"
                            ></i>{" "}
                            Sign up with Facebook
                          </button>
                        </div>
                      )}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 p-5">
                  <GlobalTextInput
                    type="text"
                    className="form-control"
                    name="fullname"
                    placeholder="Enter your full name"
                    value={formValue.fullname}
                    onChange={_onInputChange}
                    maxLength={255}
                    error={
                      submit &&
                      (isSpecialChar(formValue.fullname) ||
                        isBlank(formValue.fullname))
                    }
                    errorMessage="Please enter valid full name."
                  />
                  <GlobalTextInput
                    type="text"
                    className="form-control mt-3"
                    name="email"
                    placeholder="Enter your email address"
                    value={formValue.email}
                    onChange={_onInputChange}
                    error={
                      submit &&
                      (isBlank(formValue.email) ||
                        !isValidEmail(formValue.email))
                    }
                    errorMessage="Please enter a valid email address."
                  />
                  <GlobalTextInputForDate
                    type={isDate ? "date" : "text"}
                    onFocus={() => {
                      setIsDate(true)
                    }}
                    className="form-control mt-3"
                    name="dateOfBirth"
                    placeholder="Select date of birth"
                    value={formValue.dateOfBirth}
                    onChange={_onInputChange}
                    error={submit && isBlank(formValue.dateOfBirth)}
                    max={moment().format("YYYY-MM-DD")}
                    errorMessage="Please select date of birth."
                  />
                  {/* <GlobalTextInput
                      type="tel"
                      className="form-control mt-3"
                      name="mobile"
                      placeholder="Enter your phone number"
                      value={formValue.mobile}
                      onChange={_onInputChange}
                      error={
                        submit &&
                        (isBlank(formValue.mobile) ||
                          !isValidNumber(formValue.mobile))
                      }
                      errorMessage="Please enter your number."
                    /> */}
                  {/* <div style={{ display: 'flex' }}> */}
                  <div className="mt-3">
                    <PhoneInput
                      inputProps={{
                        style: { width: "100%", height: 45 },
                      }}
                      // className="phoneInput"
                      country={"ng"}
                      value={contactNumberWithCode}
                      onChange={(phone, e) => {
                        setContactNumberWithCode(phone)
                        setSelectedCode(e.dialCode)
                        setContactNumber(phone.slice(e.dialCode.length))
                      }}
                    />
                    {submit && isBlank(contactNumber) && (
                      <InlineError message={"Please enter your number"} />
                    )}
                    {/* <GlobalTextInput
                        type="text"
                        className="form-control mt-3"
                        name="countryCode"
                        placeholder="Country code"
                        value={formValue.countryCode}
                        onChange={_onInputChange}
                        maxLength={5}
                        error={submit && isBlank(formValue.countryCode)}
                        errorMessage="Please Country code."
                      /> */}
                    {/* </div> */}
                    {/* <div style={{ width: '70%' }}>
                      <GlobalTextInput
                        type="text"
                        className="form-control mt-3"
                        name="mobile"
                        placeholder="Enter your phone number"
                        maxLength={16}
                        value={formValue.mobile}
                        onChange={_onInputChange}
                        error={
                          submit &&
                          (isBlank(formValue.mobile) ||
                            !isValidNumber(formValue.mobile) ||
                            !(parseInt(formValue.mobile) > 0))
                        }
                        errorMessage="Please enter valid number."
                      />
                    </div> */}
                  </div>

                  <GlobalTextInput
                    type="text"
                    className="form-control mt-3"
                    name="company"
                    placeholder="Enter your company Id (optional)"
                    maxLength={16}
                    value={formValue.company}
                    onChange={_onInputChange}
                  />

                  {/* <GlobalTextInput
                      type="text"
                      className="form-control mt-3"
                      name="companyName"
                      placeholder="Enter your Company Name (optional)"
                      value={formValue.companyName}
                      onChange={_onInputChange}
                    /> */}

                  <div className="pw-div mt-3">
                    <GlobalTextInput
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      name="password"
                      placeholder="Enter your password"
                      value={formValue.password}
                      onChange={_onInputChange}
                      error={
                        submit &&
                        (isBlank(formValue.password) ||
                          !isValidPassword(formValue.password))
                      }
                      errorMessage="Password Must Contain 8-14 Characters, One Uppercase and One Number case Character."
                    />
                    <i
                      className={showPassword ? "fa fa-eye" : "fa fa-eye-slash"}
                      aria-hidden="true"
                      style={{ top: 12, cursor: "pointer" }}
                      onClick={() => {
                        setShowPassword(!showPassword)
                      }}
                    ></i>
                  </div>
                  <p className="mt-3">Have a referral code? </p>
                  <input
                    type="text"
                    className="form-control mb-3"
                    placeholder="Enter your referral code"
                    name="code"
                    maxLength={16}
                    value={formValue.code}
                    onChange={_onInputChange}
                  />
                  {error !== "" && (
                    <div>
                      <p className="error">{error}</p>
                    </div>
                  )}
                  <button
                    className="btn btn-theam-fill mt-3 w-100 mb-2"
                    onClick={signIn}
                    disabled={isSubmiting}
                  >
                    Continue
                  </button>
                </div>
              </div>
              {/* </form> */}
            </div>
          </div>
        </div>
      </div>
      <Otp
        back="#join"
        id="signupOtp"
        onSubmit={_onOtpSubmit}
        error={error}
        email={formValue.email}
      />
    </>
  )
}
