import React, { useContext } from 'react';
import Login from 'src/views/modal/Login';
import Join from 'src/views/modal/Join';
import LogOut from 'src/views/modal/LogOut';
import { AuthContext } from 'src/context/Auth';
import { NavLink } from 'react-router-dom';

export default function CustomerHeader() {
  const auth = useContext(AuthContext);
  const userType = auth.userData.userType;
  console.log(auth);
  return (
    <>
      <header>
        <div className="container-1440">
          <nav className="navbar navbar-expand-lg navbar-light pl-0 pr-0">
            <NavLink className="navbar-brand" to="/">
              <img src="/images/Logo.png" alt="logo" />
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="collapse navbar-collapse "
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav  justify-content-end">
                <li className="nav-item ">
                  <NavLink className="nav-link" to="/">
                    Home <span className="sr-only">(current)</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    activeStyle={{
                      borderBottom: '2px solid #fed325',
                    }}
                    className="nav-link"
                    to="/services"
                  >
                    Services
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    activeStyle={{
                      borderBottom: '2px solid #fed325',
                    }}
                    className="nav-link"
                    to="/practitioner"
                  >
                    Practitioners
                  </NavLink>
                </li>
                {auth.userLoggedIn && (
                  <>
                    <li className="nav-item">
                      <NavLink
                        activeStyle={{
                          borderBottom: '2px solid #fed325',
                        }}
                        className="nav-link"
                        to="/medicals"
                      >
                        Medicals
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        activeStyle={{
                          borderBottom: '2px solid #fed325',
                        }}
                        className="nav-link"
                        to="/product-categories"
                      >
                        Shops
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        activeStyle={{
                          borderBottom: '2px solid #fed325',
                        }}
                        className="nav-link"
                        to="/gift-cards"
                      >
                        Gift Cards
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        activeStyle={{
                          borderBottom: '2px solid #fed325',
                        }}
                        className="nav-link"
                        to="/refferals"
                      >
                        Referrals
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        activeStyle={{
                          borderBottom: '2px solid #fed325',
                        }}
                        className="nav-link"
                        to="/loyalty-points"
                      >
                        Loyalty points
                      </NavLink>
                    </li>
                    <li className="nav-item notification-li">
                      <NavLink className="nav-link" to="/notification">
                        <i className="fa fa-bell" aria-hidden="true"></i>
                      </NavLink>
                    </li>
                    {userType !== 'PRACTITIONER' && (
                      <li className="nav-item notification-li">
                        <NavLink className="nav-link" to="/cart">
                          <i
                            className="fa fa-shopping-cart"
                            aria-hidden="true"
                          ></i>
                        </NavLink>
                      </li>
                    )}
                    <li className="nav-item dropdown user-profile">
                      <a
                        className="nav-link user-profile1"
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src={
                            auth.userData.profilePic &&
                            auth.userData.profilePic !== ''
                              ? auth.userData.profilePic
                              : '/images/profileImage.png'
                          }
                          alt="profile"
                        />
                      </a>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <NavLink className="dropdown-item" to="/my-profile">
                          My Profile
                        </NavLink>
                        <NavLink className="dropdown-item" to="/favourites">
                          Favourites
                        </NavLink>
                        <NavLink className="dropdown-item" to="/bookings">
                          Bookings
                        </NavLink>
                        <NavLink className="dropdown-item" to="/past-purchases">
                          Purchases
                        </NavLink>
                        <NavLink className="dropdown-item" to="/cart">
                          My Cart
                        </NavLink>
                        <NavLink className="dropdown-item" to="/loyalty-points">
                          Rewards
                        </NavLink>
                        <NavLink className="dropdown-item" to="/messages">
                          Messages
                        </NavLink>
                        <a
                          className="dropdown-item"
                          href="#"
                          data-toggle="modal"
                          data-target="#logout"
                        >
                          Log Out{' '}
                        </a>
                      </div>
                    </li>
                  </>
                )}
                {!auth.userLoggedIn && (
                  <>
                    <li className="nav-item">
                      <NavLink
                        activeStyle={{
                          borderBottom: '2px solid #fed325',
                        }}
                        className="nav-link"
                        to="/product-categories"
                      >
                        Products
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link btn btn-theam"
                        href="#"
                        data-toggle="modal"
                        data-target="#login"
                      >
                        Sign in
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link btn btn-theam"
                        href="#"
                        data-toggle="modal"
                        data-target="#join"
                      >
                        {' '}
                        Join
                      </a>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </nav>
        </div>
      </header>

      <Login />
      <Join />
      <LogOut />
    </>
  );
}
