import React, {useContext} from 'react';
import {AuthContext} from 'src/context/Auth';
import { useHistory } from 'react-router-dom';
export default function LogOut() {
    const auth = useContext(AuthContext);
    const history = useHistory();
    const loggedOut = ()=>{
        auth.userLogIn(false, null);
        history.push('/');
    }
    return (
        <div className="modal fade" id="logout" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div className="modal-body login-body">
                        <h2>Log Out</h2>

                        <h6 className="text-center mt-5 mb-5">Are you sure you want to log out ?</h6>

                        <div className="btn-flex">
                            <button className="btn btn-theam-blanck mt-3 " data-dismiss="modal">No</button>
                            <button className="btn btn-theam-fill mt-3 " data-dismiss="modal" onClick={loggedOut}>Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}