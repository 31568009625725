import React, { useContext, useEffect } from 'react';
import { AuthContext } from 'src/context/Auth';

export default function PageHeader(props) {
  const { children, header } = props;
  const auth = useContext(AuthContext);
  useEffect(() => {
    auth.updateHeader(header);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <>{children}</>;
}
