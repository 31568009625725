import React, {useContext} from 'react';
import {AuthContext} from 'src/context/Auth';
import CustomerHeader from './CustomerHeader';
import PractitionerHeader from './PractitionerHeader';

export default function Header() {
    const auth = useContext(AuthContext);
    // console.log(auth.practitionerHeader);
    if(auth.practitionerHeader){
        return (
            <PractitionerHeader/>
        )
    }else{
        return (
            <CustomerHeader/>
        )
    }
    
}