export function isBlank(value) {
  return value === null || value.match(/^ *$/) !== null;
}
export function isBlankDoc(value) {
  if (value) {
    return value == null || value.match(/^ *$/) !== null;
  } else {
    return true;
  }
}
export function isValidEmail(value) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value).toLowerCase());
}
export function isValidPassword(value) {
  var re = /^(?=.*\d)(?=.*[A-Z]).{8,14}$/;
  return re.test(value);
}
export function isValidNumber(value) {
  const re = /^[0-9]+$/;
  return re.test(value);
}

export function isValidCardNumber(value) {
  const re = /^[0-9]{16}$/;
  return re.test(value);
}

export function isValidCardExpiry(value) {
  const re = /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/;
  return re.test(value);
}
export function isValidZip(value) {
  const re = /^[1-9][0-9]{5}$/;
  return re.test(value);
}

export function isValidcvv(value) {
  const re = /^[1-9][0-9]{2}$/;
  return re.test(value);
}

export function isValidAlphabet(value) {
  const re = /^[A-Z a-z]+$/;
  return re.test(value);
}

export function isSpecialChar(value) {
  if (/[^a-zA-Z0-9\-\/\s]/.test(value)) {
    return true;
  }
  return false;
}
