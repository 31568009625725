import React from 'react';
import InlineError from 'src/component/InlineError';

export const GlobalTextInput = (props) => {
  return (
    <>
      <input
        value={props.value}
        name={props.name}
        onChange={(e) => props.onChange(e)}
        type={props.type}
        className={props.className}
        id={props.id}
        placeholder={props.placeholder}
        disabled={props.disabled}
        maxLength={props.maxLength ? props.maxLength : 255}
        min={props.min ? props.min : null}
        max={props.max ? props.max : null}
        accept={props.accept ? props.accept : null}
      />
      {props.error && <InlineError message={props.errorMessage} />}
    </>
  );
};

export const GlobalTextInputForDate = (props) => {
  return (
    <>
      <input
        onFocus={props.onFocus}
        value={props.value}
        name={props.name}
        onChange={(e) => props.onChange(e)}
        type={props.type}
        className={props.className}
        id={props.id}
        placeholder={props.placeholder}
        disabled={props.disabled}
        max={props.max ? props.max : null}
        min={props.min ? props.min : null}
      />
      {props.error && <InlineError message={props.errorMessage} />}
    </>
  );
};

export const GlobalTextAreaInput = (props) => {
  return (
    <>
      <textarea
        value={props.value}
        name={props.name}
        onChange={(e) => props.onChange(e)}
        type={props.type}
        className={props.className}
        id={props.id}
        rows={props.rows}
        placeholder={props.placeholder}
        disabled={props.disabled}
        maxLength={props.maxLength ? props.maxLength : 255}
      />
      {props.error && <InlineError message={props.errorMessage} />}
    </>
  );
};
