import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import ApiConfig from 'src/config/ApiConfig';

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Mo ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkGuest(userType) {
  return userType === 'GUEST' ? false : true;
}
function checkLogin() {
  const accessToken = window.localStorage.getItem('accessToken');
  const guest = window.localStorage.getItem('geust');
  return accessToken && guest !== 'true' ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [practitionerHeader, setPractitionerHeader] = useState(false);
  const [userData, setUserData] = useState({});

  let data = {
    userLoggedIn: isLogin,
    practitionerHeader,
    userData,
    userLogIn: (type, userDataAPI) => {
      if (type) {
        setSession(userDataAPI);
        data.getProfile();
      } else {
        setSession(userDataAPI);
        setUserData({});
      }
      setIsLogin(type);
    },
    updateHeader: (dataAPI) => {
      setPractitionerHeader(dataAPI);
    },
    getProfile: async () => {
      const guest = await guestLogin();
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        
        const res = await axios.get(ApiConfig.getProfile, {
          headers: {
            token: accessToken,
          },
        });
        // console.log(res);
        if (res.data.responseCode !== 200) {
          setIsLogin(false);
          setSession(guest);
          localStorage.setItem('geust', 'true');
        } else {
          const notGuest = checkGuest(res.data.result.userType);
          if (notGuest) {
            setIsLogin(true);
            setUserData(res.data.result);
            localStorage.removeItem('geust');
          } else {
            setIsLogin(false);
            setSession(guest);
            localStorage.setItem('geust', 'true');
          }
        }
      } catch (err) {
        setIsLogin(false);
        setSession(guest);
        localStorage.setItem('geust', 'true');
      }
    },
  };
  const guestLogin = async () => {
    const res = await axios.get(ApiConfig.guestLogin);
    return res.data.result.token;
  };
  useEffect(() => {
    data.getProfile();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
