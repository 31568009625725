// let url;
let url = "https://responsiveapp-api.mobiloitte.com"
// if (process.env.REACT_APP_ENV !== "live") {
//   url = "https://responsiveapp-api.mobiloitte.com";
// } else {
//   url = "https://responsiveapp-api.mobiloitte.com";
// }

export const facebookAppId = "634360044369818"

const ApiConfig = {
  login: `${url}/api/v1/user/logIn`,
  signup: `${url}/api/v1/user/signup`,
  forgotPassword: `${url}/api/v1/user/forgotPassword`,
  resetPassword: `${url}/api/v1/user/resetPassword`,
  resendOTP: `${url}/api/v1/user/resendOTP`,
  verifyOTP: `${url}/api/v1/user/verifyOTP`,
  getProfile: `${url}/api/v1/user/getProfile`,
  addVaccine: `${url}/api/v1/user/addVaccine`,
  filterInVaccine: `${url}/api/v1/user/filterInVaccine`,
  deleteVaccine: `${url}/api/v1/user/deleteVaccine`,
  addAllergy: `${url}/api/v1/user/addAllergy`,
  addPrescription: `${url}/api/v1/user/addPrescription`,
  filterInAllergy: `${url}/api/v1/user/filterInAllergy`,
  deleteAllergy: `${url}/api/v1/user/deleteAllergy`,
  filterInPrescription: `${url}/api/v1/user/filterInPrescription`,
  deletePrescription: `${url}/api/v1/user/deletePrescription`,
  addBloodPressure: `${url}/api/v1/user/addBloodPressure`,
  filterInBloodPressure: `${url}/api/v1/user/filterInBloodPressure`,
  deleteBloodPressure: `${url}/api/v1/user/deleteBloodPressure`,
  addDoctor: `${url}/api/v1/user/addDoctor`,
  filterInDoctor: `${url}/api/v1/user/filterInDoctor`,
  deleteDoctor: `${url}/api/v1/user/deleteDoctor`,
  addGlucose: `${url}/api/v1/user/addGlucose`,
  filterInGlucose: `${url}/api/v1/user/filterInGlucose`,
  deleteGlucose: `${url}/api/v1/user/deleteGlucose`,
  socialLogin: `${url}/api/v1/user/socialLogin`,
  notificationList: `${url}/api/v1/practioner/notificationList`,
  notificationDelete: `${url}/api/v1/practioner/deleteNotification/`,
  userNotificationList: `${url}/api/v1/user/notificationList`,
  earningList: `${url}/api/v1/practioner/earningList`,
  getTotalEarning: `${url}/api/v1/practioner/getTotalEarning`,
  deleteEarning: `${url}/api/v1/practioner/deleteEarning`,
  getCountJob: `${url}/api/v1/practioner/getCountJob`,
  requestList: `${url}/api/v1/practioner/requestList`,
  acceptRequest: `${url}/api/v1/practioner/acceptRequest`,
  rejectRequest: `${url}/api/v1/practioner/rejectRequest`,
  blockUser: `${url}/api/v1/practioner/blockUser`,
  addPractitionerToCart: `${url}/api/v1/user/addPractitionerToCart`,
  myGiftCardList: `${url}/api/v1/user/myGiftCardList`,
  myReferralCode: `${url}/api/v1/user/myReferralCode`,
  userNotificationDelete: `${url}/api/v1/user/deleteNotification/`,
  addAddress: `${url}/api/v1/user/addAddress`,
  praaddAddress: `${url}​/api​/v1​/practioner​/addAddress`,
  bookPractitionerAppointment: `${url}/api/v1/user/bookPractitionerAppointment`,
  deleteBooking: `${url}/api/v1/user/deleteBooking`,
  cancelBookingU: `${url}/api/v1/user/cancelBooking`,
  shareReview: `${url}/api/v1/user/shareReview`,
  upcomingBookingPractitioner: `${url}/api/v1/user/upcomingBookingPractitioner`,
  pastBookingPractitioner: `${url}/api/v1/user/pastBookingPractitioner`,
  serviceList: `${url}/api/v1/user/serviceList`,
  practitionerList: `${url}/api/v1/user/practitionerList`,
  favouriteUnfavouriteService: `${url}/api/v1/user/favouriteUnfavouriteService`,
  favouriteUnfavouritePractitioner: `${url}/api/v1/user/favouriteUnfavouritePractitioner`,
  addPersonalDetail: `${url}/api/v1/user/addPersonalDetail`,
  viewPersonalDetail: `${url}/api/v1/user/viewPersonalDetail`,
  filterInAppointment: `${url}/api/v1/user/filterInAppointment`,
  addAppointment: `${url}/api/v1/user/addAppointment`,
  deleteAppointment: `${url}/api/v1/user/deleteAppointment`,
  addLabTest: `${url}/api/v1/user/addLabTest`,
  deleteLabTest: `${url}/api/v1/user/deleteLabTest`,
  filterInLabTest: `${url}/api/v1/user/filterInLabTest`,
  addExamination: `${url}/api/v1/user/addExamination`,
  deleteExamination: `${url}/api/v1/user/deleteExamination`,
  filterInExamination: `${url}/api/v1/user/filterInExamination`,
  addDocument: `${url}/api/v1/user/addDocument`,
  deleteDocument: `${url}/api/v1/user/deleteDocument`,
  filterInDocument: `${url}/api/v1/user/filterInDocument`,
  shareMedicalDetails: `${url}/api/v1/user/shareMedicalDetails`,
  bannerList: `${url}/api/v1/user/bannerList`,
  getSubServiceList: `${url}/api/v1/admin/getSubServiceList`,
  searchPractitioner: `${url}/api/v1/user/searchPractitioner`,
  getPractitionerDetails: `${url}/api/v1/practioner/getPractitionerDetails`,
  productCategoryList: `${url}/api/v1/user/productCategoryList`,
  productListCategoryWise: `${url}/api/v1/user/productListCategoryWise`,
  viewProductDetails: `${url}/api/v1/user/viewProductDetails`,
  addProductToCart: `${url}/api/v1/user/addProductToCart`,
  getCartList: `${url}/api/v1/user/getCartList`,
  removeFromCart: `${url}/api/v1/user/removeFromCart`,
  getAddress: `${url}/api/v1/user/getAddress`,
  payStack: `${url}/api/v1/payment/payStack`,
  cardList: `${url}/api/v1/user/cardList`,
  addCard: `${url}/api/v1/user/addCard`,
  listOfPurchasedProduct: `${url}/api/v1/user/listOfPurchasedProduct`,
  deletePurchaseProduct: `${url}/api/v1/user/deletePurchaseProduct`,
  buyNow: `${url}/api/v1/user/buyNow`,
  guestLogin: `${url}/api/v1/user/guestLogin`,
  favouriteServiceList: `${url}/api/v1/user/favouriteServiceList`,
  favouritePractitionerList: `${url}/api/v1/user/favouritePractitionerList`,
  favouriteProductList: `${url}/api/v1/user/favouriteProductList`,
  faqsList: `${url}/api/v1/faq/faqsList`,
  userServiceDetails: `${url}/api/v1/user/userServiceDetails`,
  viewStaticPage: `${url}/api/v1/static/viewStaticPage`,
  userMedicalEdit: `${url}/api/v1/user/userMedicalEdit`,
  appSharingData: `${url}/api/v1/appSharing/appSharingData`,
  getPractionerProfile: `${url}/api/v1/practioner/getProfile`,
  editPractionerProfile: `${url}/api/v1/practioner/editProfile`,
  upcomingBookingListPractitioner: `${url}/api/v1/practioner/upcomingBookingListPractitioner`,
  pastBookingListPractitioner: `${url}/api/v1/practioner/pastBookingListPractitioner`,
  cancelBooking: `${url}/api/v1/practioner/cancelBooking`,
  addPractionerDetail: `${url}/api/v1/practioner/addPractionerDetail`,
  getServiceList: `${url}/api/v1/admin/getServiceList`,
  changePassword: `${url}/api/v1/practioner/changePassword`,
  bookAppointment: `${url}/api/v1/practioner/bookAppointment`,
  homeScreenSearch: `${url}/api/v1/user/homeScreenSearch`,
  todayRequest: `${url}/api/v1/practioner/todayRequest`,
  viewLatestJob: `${url}/api/v1/practioner/viewLatestJob`,
  favouriteUnfavouriteProduct: `${url}/api/v1/user/favouriteUnfavouriteProduct`,
  bookingList: `${url}/api/v1/practioner/bookingList`,
  getHomeContent: `${url}/api/v1/user/getHomeContent`,
  getOurServicesContent: `${url}/api/v1/user/getOurServicesContent`,
  getOurProductsContent: `${url}/api/v1/user/getOurProductsContent`,
  getHomeScreenTypeContent: `${url}/api/v1/user/getHomeScreenTypeContent`,
  professionalServiceList: `${url}/api/v1/user/professionalServiceList`,
  getPractitionerScreenContent: `${url}/api/v1/user/getPractitionerScreenContent`,
  getPractitionerSignInContent: `${url}/api/v1/user/getPractitionerSignInContent`,
  myLoyalityPoint: `${url}/api/v1/user/myLoyalityPoint`,
  myReferralHistory: `${url}/api/v1/user/myReferralHistory`,
  getPractitionerContent: `${url}/api/v1/user/getPractitionerContent`,
  queryRaise: `${url}/api/v1/user/queryRaise`,
  editProfile: `${url}/api/v1/user/editProfile`,
  searchProductService: `${url}/api/v1/user/searchProductService`,
  getSubServiceListUser: `${url}/api/v1/user/getSubServiceList`,
  expertList: `${url}/api/v1/user/expertList`,
  listOfUsers: `${url}/api/v1/user/listOfUsers`,
  getContactUsContent: `${url}/api/v1/user/getContactUsContent`,
}

export default ApiConfig
