import React, { useContext, useState } from "react"
import { AuthContext } from "src/context/Auth"
import {
  isBlank,
  isValidEmail,
  isValidPassword,
} from "src/component/FormValidation"
import { GlobalTextInput } from "src/component/GlobalTextInput"
import axios from "axios"
import ApiConfig, { facebookAppId } from "src/config/ApiConfig"
import Otp from "src/views/modal/Otp"
import { useHistory } from "react-router-dom"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"

const customHeader = {}
customHeader["Test"] = "test-header"

export default function Login(props) {
  const remember = window.localStorage.getItem("remember")
  const rememberData = JSON.parse(remember)
  const history = useHistory()
  const auth = useContext(AuthContext)
  const [formValue, setFormValue] = useState({
    email: rememberData && rememberData.remember ? rememberData.email : "",
    password:
      rememberData && rememberData.remember ? rememberData.password : "",
    mobile: "",
    confirm_password: "",
    new_password: "",
  })
  const [submit, setSubmit] = useState(false)
  const [isSubmiting, setIsSubmiting] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [passwordUpdated, setPasswordUpdated] = useState(false)
  const [reSendOTP, setReSendOTP] = useState(false)
  const [error, setError] = useState("")
  const [isRemember, setIsRemember] = useState(
    rememberData && rememberData.remember ? rememberData.remember : false
  )

  const logIn = async (e) => {
    if (e) {
      e.preventDefault()
    }
    setSubmit(true)
    setError("")
    if (
      !isBlank(formValue.email) &&
      isValidEmail(formValue.email) &&
      !isBlank(formValue.password)
    ) {
      setIsSubmiting(true)

      try {
        const res = await axios.post(ApiConfig.login, {
          email: formValue.email,
          password: formValue.password,
        })
        if (res.data.responseCode !== 200) {
          setError(res.data.responseMessage)
        } else {
          // window.$('#otp').modal('show');
          if (isRemember) {
            const data = {
              email: formValue.email,
              password: formValue.password,
              remember: isRemember,
            }
            localStorage.setItem("remember", JSON.stringify(data))
          } else {
            localStorage.removeItem("remember")
          }
          if (res.data.result.otpVerification === false) {
            window.$("#otp").modal("show")
            window.$("#login").modal("hide")
            const temp = { ...formValue, mobile: res.data.result.mobileNumber }
            setFormValue(temp)
            setReSendOTP(true)
          } else {
            auth.userLogIn(true, res.data.result.token)
            window.$("#login").modal("hide")
            setSubmit(false)
            if (props.practitioner === "practitioner") {
              history.push("/practitioner/home")
            }
          }
        }
        setIsSubmiting(false)
      } catch (err) {
        setError("Something went wrong!")
        setIsSubmiting(false)
      }
    }
  }
  const forgotPassword = async (e) => {
    e.preventDefault()
    setSubmit(true)
    setError("")
    if (!isBlank(formValue.email) && isValidEmail(formValue.email)) {
      setIsSubmiting(true)
      try {
        const res = await axios.post(ApiConfig.forgotPassword, {
          email: formValue.email,
        })
        if (res.data.responseCode !== 200) {
          setError(res.data.responseMessage)
        } else {
          window.$("#forgot_password").modal("hide")
          window.$("#otp").modal("show")
          setSubmit(false)
        }
        setIsSubmiting(false)
      } catch (err) {
        setError("Something went wrong!")
        setIsSubmiting(false)
      }
    }
  }

  const _onInputChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    const temp = { ...formValue, [name]: value }
    setFormValue(temp)
  }

  const _onOtpSubmit = async (otp) => {
    setError("")
    try {
      const res = await axios.post(ApiConfig.verifyOTP, {
        email: formValue.email,
        otp,
      })
      if (res.data.responseCode !== 200) {
        setError(res.data.responseMessage)
      } else {
        window.$("#otp").modal("hide")
        if (reSendOTP) {
          logIn()
          setReSendOTP(false)
        } else {
          window.$("#reset_password").modal("show")
          setSubmit(false)
        }
      }
      setIsSubmiting(false)
    } catch (err) {
      setError("Something went wrong!")
      setIsSubmiting(false)
    }
  }

  const resetPassword = async (e) => {
    e.preventDefault()
    setSubmit(true)
    if (
      !isBlank(formValue.new_password) &&
      isValidPassword(formValue.new_password) &&
      !isBlank(formValue.confirm_password) &&
      formValue.new_password === formValue.confirm_password
    ) {
      setIsSubmiting(true)
      try {
        const res = await axios.post(ApiConfig.resetPassword, {
          email: formValue.email,
          newPassword: formValue.new_password,
          confirmPassword: formValue.confirm_password,
        })
        // console.log(res.data)
        if (res.data.responseCode !== 200) {
          setError(res.data.responseMessage)
        } else {
          // window.$('#forgot_password').modal('hide');
          // window.$('#otp').modal('show');
          setSubmit(false)
          setPasswordUpdated(true)
        }
        setIsSubmiting(false)
      } catch (err) {
        setError("Something went wrong!")
        setIsSubmiting(false)
      }
      // window.$('#reset_password').modal('hide');
      // setSubmit(false);
    }
  }
  const resetState = () => {
    setFormValue({
      email: "",
      password: "",
      mobile: "",
      confirm_password: "",
      new_password: "",
    })
    setSubmit(false)
    setPasswordUpdated(false)
    setShowPassword(false)
  }

  const responseFacebook = (response) => {
    console.log("DDDDDDDDDD", response)
    if (response.name) {
      var name = response.name && response.name.split(" ")
      let body = {
        socialId: response.id,
        socialType: "facebook",
        email: response.email,
        firstName: name[0],
        lastName: name[1],
        socialImage: response.picture.data.url,
      }
      socialLogin(body)
    }
  }
  const socialLogin = async (body) => {
    const options = {
      method: "POST",
      data: body,
      url: ApiConfig.socialLogin,
    }

    const res = await axios(options)

    if (res.data.responseCode !== 200) {
      setError(res.data.responseMessage)
    } else {
      if (res.data.result.otpVerification === false) {
        window.$("#otp").modal("show")
        window.$("#login").modal("hide")
        const temp = { ...formValue, mobile: res.data.result.mobileNumber }
        setFormValue(temp)
        setReSendOTP(true)
      } else {
        auth.userLogIn(true, res.data.result.token)
        window.$("#login").modal("hide")
        setSubmit(false)
      }
    }
    setIsSubmiting(false)
  }

  return (
    <>
      <div
        className="modal fade"
        id="login"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="modal-body login-body">
              <h2>Sign In</h2>
              <GlobalTextInput
                type="text"
                className="form-control"
                name="email"
                placeholder="Enter your email address"
                value={formValue.email}
                onChange={_onInputChange}
                error={
                  submit &&
                  (isBlank(formValue.email) || !isValidEmail(formValue.email))
                }
                errorMessage="Please enter your registered email address."
              />
              <div className="pw-div mt-3">
                <GlobalTextInput
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  name="password"
                  placeholder="Enter your password"
                  value={formValue.password}
                  onChange={_onInputChange}
                  error={submit && isBlank(formValue.password)}
                  errorMessage="Please enter a valid password."
                />
                <i
                  className={showPassword ? "fa fa-eye-slash" : "fa fa-eye"}
                  aria-hidden="true"
                  style={{ top: 12, cursor: "pointer" }}
                  onClick={() => {
                    setShowPassword(!showPassword)
                  }}
                ></i>
              </div>
              <label className="remember mt-3">
                Remember me
                <input
                  type="checkbox"
                  checked={isRemember}
                  onClick={() => setIsRemember(!isRemember)}
                />
                <span className="checkmark"></span>
              </label>
              {error !== "" && (
                <div className="pt-2">
                  <p className="error">{error}</p>
                </div>
              )}
              <button
                className="btn btn-theam-fill mt-3 w-100"
                onClick={logIn}
                disabled={isSubmiting}
              >
                Continue
              </button>
              <div className="text-right mt-1">
                <a
                  href="#"
                  data-toggle="modal"
                  data-target="#forgot_password"
                  data-dismiss="modal"
                  onClick={resetState}
                >
                  Forgot password{" "}
                </a>
              </div>
              {props.practitioner !== "practitioner" ? (
                <p className="mt-5">Or Sign In With-</p>
              ) : (
                ""
              )}
              {/* <div className="btn-fb mt-2">
                {" "}
                <i className="fa fa-twitter" aria-hidden="true"></i>{" "}
                <button className="btn btn-theam-fill mt-3 w-100">
                  Sign up with Twitter{" "}
                </button>
              </div> */}

              {/* <TwitterLogin 
              loginUrl="http://localhost:3000/"

      //  loginUrl="http://localhost:4000/api/v1/auth/twitter"
                      onFailure={onFailed}
                      onSuccess={onSuccess}
                      requestTokenUrl="http://localhost:3000/"

                      // requestTokenUrl="http://localhost:4000/api/v1/auth/twitter/reverse"
                      showIcon={true}
                      customHeaders={customHeader}
                      forceLogin={true}/>

        <TwitterLogin 
                loginUrl="http://localhost:3000/"

       // loginUrl="http://localhost:4000/api/v1/auth/twitter"
                      onFailure={onFailed}
                      onSuccess={onSuccess}
                    //  requestTokenUrl="http://localhost:4000/api/v1/auth/twitter/reverse"
                      showIcon={true}
                      customHeaders={customHeader}>
          <b>Custom</b> Twitter <i>Login</i> content
        </TwitterLogin> */}

              {props.practitioner !== "practitioner" ? (
                <FacebookLogin
                  appId={facebookAppId}
                  callback={responseFacebook}
                  autoLoad={false}
                  fields="name,email,picture"
                  scope="public_profile, email"
                  render={(renderProps) => (
                    <div className="btn-fb2 mt-2">
                      {" "}
                      <button
                        className="btn btn-theam-fill mt-3 w-100"
                        onClick={renderProps.onClick}
                      >
                        <i className="fa fa-facebook" aria-hidden="true"></i>{" "}
                        Sign up with Facebook
                      </button>
                    </div>
                  )}
                />
              ) : (
                ""
              )}
              {props.practitioner !== "practitioner" ? (
                <div className="text-center mt-3">
                  Not a member yet ?{" "}
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target="#join"
                    data-dismiss="modal"
                  >
                    {" "}
                    Join now{" "}
                  </a>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="forgot_password"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="modal-body login-body">
              <h2>Forgot Password </h2>
              <form>
                <h6 className="forgot-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Aenean euismod bibendum laoreet. Proin gravida dolor sit amet
                  lacus accumsan
                </h6>
                <GlobalTextInput
                  type="text"
                  className="form-control"
                  name="email"
                  placeholder="Enter your email address"
                  value={formValue.email}
                  onChange={_onInputChange}
                  error={
                    submit &&
                    (isBlank(formValue.email) ||
                      !isValidEmail(formValue.email) ||
                      error !== "")
                  }
                  errorMessage="Please enter your registered email address."
                />
                <div className="btn-flex">
                  <button
                    className="btn btn-theam-blanck mt-3 "
                    data-toggle="modal"
                    data-target="#login"
                    data-dismiss="modal"
                  >
                    Back
                  </button>
                  <button
                    className="btn btn-theam-fill mt-3 "
                    disabled={isSubmiting}
                    onClick={forgotPassword}
                  >
                    Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Otp
        back="#forgot_password"
        id="otp"
        onSubmit={_onOtpSubmit}
        email={formValue.email}
        error={error}
        reSendOTP={reSendOTP}
      />

      <div
        className="modal fade"
        id="reset_password"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="modal-body login-body">
              <h2>Reset Password </h2>
              <form>
                <h6 className="forgot-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Aenean euismod bibendum laoreet. Proin gravida dolor sit amet
                  lacus accumsan
                </h6>
                <div className="pw-div mt-3">
                  <GlobalTextInput
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    name="new_password"
                    placeholder="Enter a new password"
                    value={formValue.new_password}
                    onChange={_onInputChange}
                    error={
                      submit &&
                      (isBlank(formValue.new_password) ||
                        !isValidPassword(formValue.new_password))
                    }
                    errorMessage="Password Must Contain 8-14 Characters, One Uppercase and One Number case Character."
                  />
                  <i
                    className={showPassword ? "fa fa-eye-slash" : "fa fa-eye"}
                    aria-hidden="true"
                    style={{ top: 12, cursor: "pointer" }}
                    onClick={() => {
                      setShowPassword(!showPassword)
                    }}
                  ></i>
                </div>

                <GlobalTextInput
                  type="password"
                  className="form-control mt-3"
                  name="confirm_password"
                  placeholder="Confirm your password"
                  value={formValue.confirm_password}
                  onChange={_onInputChange}
                  error={
                    submit &&
                    formValue.new_password !== formValue.confirm_password
                  }
                  errorMessage="Password not matched."
                />
                {passwordUpdated && (
                  <div className="pt-3">
                    <p style={{ color: "#83B63C" }}>
                      Your password has been successfully changed.
                    </p>
                  </div>
                )}
                {!passwordUpdated && (
                  <div className="btn-flex mt-3">
                    <button
                      className="btn btn-theam-blanck mt-3 "
                      data-toggle="modal"
                      data-target="#otp"
                      data-dismiss="modal"
                    >
                      Back
                    </button>
                    <button
                      className="btn btn-theam-fill mt-3 "
                      disabled={isSubmiting}
                      onClick={resetPassword}
                    >
                      Continue
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
