import React, { useContext } from 'react';
import Login from 'src/views/modal/Login';
import Join from 'src/views/modal/Join';
import LogOut from 'src/views/modal/LogOut';
import { AuthContext } from 'src/context/Auth';
import { NavLink, useHistory } from 'react-router-dom';

export default function PractitionerHeader() {
  const auth = useContext(AuthContext);
  const history = useHistory();
  const userType = auth.userData.userType;
  //   const userType = auth.userData!== null ? auth.userData.userType: null;
  //   console.log(auth);
  return (
    <>
      <header>
        <div className="container-1440">
          <nav className="navbar navbar-expand-lg navbar-light pl-0 pr-0">
            {auth.userLoggedIn && userType === 'PRACTITIONER' ? (
              <NavLink className="navbar-brand" to="/practitioner/home">
                <img src="/images/Logo.png" alt="logo" />
              </NavLink>
            ) : (
              <NavLink className="navbar-brand" to="/">
                <img src="/images/Logo.png" alt="logo" />
              </NavLink>
            )}

            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="collapse navbar-collapse "
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav  justify-content-end">
                {auth.userLoggedIn && userType === 'PRACTITIONER' && (
                  <>
                    <li className="nav-item">
                      <NavLink
                        activeStyle={{
                          borderBottom: '2px solid #fed325',
                        }}
                        className="nav-link"
                        to="/practitioner/home"
                      >
                        Home
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        activeStyle={{
                          borderBottom: '2px solid #fed325',
                        }}
                        className="nav-link"
                        to="/practitioner/bookings"
                      >
                        Bookings
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        activeStyle={{
                          borderBottom: '2px solid #fed325',
                        }}
                        className="nav-link"
                        to="/practitioner/requests"
                      >
                        Requests
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        activeStyle={{
                          borderBottom: '2px solid #fed325',
                        }}
                        className="nav-link"
                        to="/practitioner/earnings"
                      >
                        Earnings
                      </NavLink>
                    </li>
                    <li className="nav-item notification-li">
                      <NavLink
                        className="nav-link"
                        to="/practitioner/notification"
                      >
                        <i className="fa fa-bell" aria-hidden="true"></i>
                      </NavLink>
                      {/* <div className="notification-text">10</div> */}
                    </li>
                    <li className="nav-item dropdown user-profile">
                      <a
                        className="nav-link user-profile1"
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src={
                            auth.userData.profilePic !== ''
                              ? auth.userData.profilePic
                              : '/images/profileImage.png'
                          }
                          alt="profile"
                        />
                      </a>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <NavLink
                          className="dropdown-item"
                          to="/practitioner/my-profile"
                        >
                          My Profile
                        </NavLink>
                        <NavLink
                          className="dropdown-item"
                          to="/practitioner/change-password"
                        >
                          Change Password
                        </NavLink>
                        <NavLink
                          className="dropdown-item"
                          to="/practitioner/messages"
                        >
                          Messages
                        </NavLink>
                        <a
                          className="dropdown-item"
                          href="#"
                          data-toggle="modal"
                          data-target="#logout"
                        >
                          Log Out{' '}
                        </a>
                      </div>
                    </li>
                  </>
                )}
                {(!auth.userLoggedIn || userType !== 'PRACTITIONER') && (
                  <>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/">
                        Home
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        activeStyle={{
                          borderBottom: '2px solid #fed325',
                        }}
                        className="nav-link"
                        to="/services"
                      >
                        Services
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        activeStyle={{
                          borderBottom: '2px solid #fed325',
                        }}
                        className="nav-link"
                        to="/practitioner"
                      >
                        Practitioners
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        activeStyle={{
                          borderBottom: '2px solid #fed325',
                        }}
                        className="nav-link"
                        to="/product-categories"
                      >
                        Products
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link btn btn-theam"
                        onClick={() => {
                          history.push('/practitioner/tutorial-and-options');
                        }}
                      >
                        Sign in
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link btn btn-theam"
                        onClick={() => {
                          history.push(
                            '/practitioner/location-and-qualifications'
                          );
                        }}
                      >
                        {' '}
                        Join
                      </a>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </nav>
        </div>
      </header>

      <Login practitioner={'practitioner'} />

      <Join />
      <LogOut />
    </>
  );
}
