import React from 'react';

export default function PageLoading() {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: 400 }}
    >
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

export function CriculerLoader() {
  return <div className="CriculerLoader"></div>;
}
