import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ApiConfig from 'src/config/ApiConfig';

export default function Otp(props) {
  const { back, onSubmit, error, id, email, reSendOTP } = props;
  const [formOtp, setFormOTP] = useState({
    first: '',
    second: '',
    third: '',
    forth: '',
  });
  const [submit, setSubmit] = useState(false);
  const [finalOtp, setFinalOtp] = useState('');
  const [isSubmiting, setIsSubmiting] = useState(false);

  const _onOtpChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const next = e.target.nextSibling;
    const temp = { ...formOtp, [name]: value };
    setFormOTP(temp);
    if (next !== null && value !== '') {
      next.focus();
    }
    const finalOtpData = `${temp.first}${temp.second}${temp.third}${temp.forth}`;
    setFinalOtp(finalOtpData);
  };
  const _onBackSpace = (e) => {
    const keyCode = e.keyCode;
    const prev = e.target.previousSibling;
    if (keyCode === 8 && prev !== null) {
      setTimeout(() => {
        prev.focus();
      });
    }
  };
  const _onSubmitOtp = (e) => {
    e.preventDefault();
    setSubmit(true);
    if (finalOtp.length === 4) {
      setSubmit(false);
      onSubmit(finalOtp);
    }
  };
  const _onResendOtp = async (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsSubmiting(true);
    try {
      const res = await axios.post(ApiConfig.resendOTP, {
        email,
      });
      console.log("otp",res.data);
      setIsSubmiting(false);
    } catch (err) {
      setIsSubmiting(false);
    }
  };
  useEffect(() => {
    if (reSendOTP) {
      _onResendOtp();
    }
  }, [reSendOTP]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setFormOTP({ first: '', second: '', third: '', forth: '' });
  }, [email]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <div
        className="modal fade"
        id={id}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="modal-body login-body">
              <h2>OTP Verification</h2>
              <form>
                <h6 className="forgot-text">
                  OTP has been sent to your registered email address.
                </h6>
                <div className="otp-div">
                  <input
                    type="text"
                    maxLength="1"
                    className="form-control"
                    placeholder=""
                    name="first"
                    value={formOtp.first}
                    onChange={_onOtpChange}
                    onKeyDown={_onBackSpace}
                  />
                  <input
                    type="text"
                    maxLength="1"
                    className="form-control"
                    placeholder=""
                    name="second"
                    value={formOtp.second}
                    onChange={_onOtpChange}
                    onKeyDown={_onBackSpace}
                  />
                  <input
                    type="text"
                    maxLength="1"
                    className="form-control"
                    placeholder=""
                    name="third"
                    value={formOtp.third}
                    onChange={_onOtpChange}
                    onKeyDown={_onBackSpace}
                  />
                  <input
                    type="text"
                    maxLength="1"
                    className="form-control"
                    placeholder=""
                    name="forth"
                    value={formOtp.forth}
                    onChange={_onOtpChange}
                    onKeyDown={_onBackSpace}
                  />
                </div>
                <div className="text-right mt-1">
                  <a href="#" onClick={_onResendOtp}>
                    {' '}
                    {isSubmiting ? 'Sending...' : 'Resend'}{' '}
                  </a>
                </div>
                {submit && finalOtp.length !== 4 && error === '' && (
                  <p className="error">Please enter OTP.</p>
                )}
                {error !== '' && (
                  <div>
                    <p className="error">{error}</p>
                  </div>
                )}
                <div className="btn-flex">
                  <button
                    className="btn btn-theam-blanck mt-3 "
                    data-toggle="modal"
                    data-target={back}
                    data-dismiss="modal"
                  >
                    Back
                  </button>
                  <button
                    className="btn btn-theam-fill mt-3 "
                    onClick={_onSubmitOtp}
                  >
                    Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
