import React, { Suspense, Fragment } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { routes } from 'src/routes';
import { createBrowserHistory } from 'history';
import AuthContext from 'src/context/Auth';
import Header from 'src/component/Header';
import Footer from 'src/component/Footer';
import PageLoading from 'src/component/PageLoading';
import AuthGuard from 'src/component/AuthGuard';
import PageHeader from 'src/component/PageHeader';

const history = createBrowserHistory();

function App() {
  return (
    <div className="App">
      <AuthContext>
        <Router history={history}>
          <Header />
          <RenderRoutes data={routes} />
          <Footer />
        </Router>
      </AuthContext>
    </div>
  );
}

export default App;

function RenderRoutes(props) {
  return (
    <Suspense fallback={<PageLoading />}>
      <Switch>
        {props.data.map((route, i) => {
          const Component = route.component;
          const Guard = route.guard ? AuthGuard : Fragment;
          const practitionerHeader = route.practitioner ? true : false;
          return (
            <Route
              exact={route.exact}
              key={i}
              path={route.path}
              render={(propsData) => (
                <PageHeader header={practitionerHeader}>
                  <Guard>
                    {route.routes ? (
                      <RenderRoutes data={route.routes} />
                    ) : (
                      <Component {...propsData} />
                    )}
                  </Guard>
                </PageHeader>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
}
