import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ApiConfig from 'src/config/ApiConfig';
import axios from 'axios';

export default function Footer() {
  const [data, setData] = useState();
  const [isloading, setIsloading] = useState(false);
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      const res = await axios.get(ApiConfig.appSharingData, {
        headers: {
          token: accessToken,
        },
      });
      if (res.data.responseCode !== 200) {
        setData();
      } else {
        // console.log("DATADATA", res.data.result);
        setData(res.data.result);
      }
      setIsloading(true);
    } catch (err) {
      setIsloading(true);
    }
  };
  return (
    <footer>
      <div className="container-1440 pt-4 borderTop">
        <div className="row">
          <div className="col-lg-2 col-md-4">
            <h6>About</h6>
            <ul>
              <li>
                <Link to="/about-alafiame">About Alafiame</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
              <li>
                <Link to="/terms-conditions">Terms & Conditions</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/faqs">FAQs</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-4">
            <h6 className="pl-5">Discover</h6>
            <ul className="pl-5">
              <li>
                <Link to="/practitioner/location-and-qualifications">
                  Become Corporate customer
                </Link>
              </li>
              <li>
                <Link to="/mobile-apps">Mobile Apps</Link>
              </li>
              <li>
                <Link to="/services">Services</Link>
              </li>
              <li>
                <Link to="/product-categories">Products</Link>
              </li>
              <li>
                <Link to="/practitioner/tutorial-and-options">Tutorials</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-2 col-md-4">
            <h6>Services</h6>
            <ul>
              <li>
                <Link to="/services-list">Home Visits</Link>
              </li>
              <li>
                <p>Video Conference</p>
              </li>
              <li>
                <Link to="/telephonic-consultation">Telephonic conference</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-5 col-md-12">
            <div className="follw-div">
              {isloading && (
                <div className="follw-div1">
                  <a
                    href={data && data.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/images/facebook.png" alt="facebook" />
                  </a>{' '}
                  &nbsp;
                  <a
                    href={data && data.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/images/in.png" alt="linkedin" />
                  </a>
                  &nbsp;
                  <a
                    href={data && data.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/images/tweeter.png" alt="tweeter" />
                  </a>
                  &nbsp;
                  <a
                    href={data && data.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/images/insta.png" alt="instagram" />
                  </a>
                  &nbsp;
                </div>
              )}

              <div className="follw-div2">
                <img src="/images/footer-Logo.png" alt="logo" />
              </div>
              <div className="follw-div3">
                <Link to="/mobile-apps">
                  <img src="/images/app-store.png" alt="app store" />
                </Link>
                &nbsp;
                <Link to="/mobile-apps">
                  <img src="/images/googel-play.png" alt="google play" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className="copy-right">All rights are reserved @Alafiame </p>
    </footer>
  );
}
