import React, { lazy } from "react";
import { Redirect } from "react-router-dom";

export const routes = [
  {
    exact: true,
    path: "/",
    component: lazy(() => import("src/views/Home")),
  },
  {
    exact: true,
    path: "/notification",
    guard: true,
    component: lazy(() => import("src/views/pages/Notification")),
  },
  {
    exact: true,
    path: "/appointments",
    guard: true,
    component: lazy(() => import("src/views/pages/Appointments")),
  },
  {
    exact: true,
    path: "/gift-cards",
    guard: true,
    component: lazy(() => import("src/views/pages/GiftCards")),
  },
  {
    exact: true,
    path: "/refferals",
    guard: true,
    component: lazy(() => import("src/views/pages/Refferals")),
  },
  {
    exact: true,
    path: "/contact-us",
    component: lazy(() => import("src/views/pages/ContactUs")),
  },
  {
    exact: true,
    path: "/service-provider",
    component: lazy(() => import("src/views/pages/Services/ServiceProvider")),
  },
  {
    exact: true,
    path: "/services",
    component: lazy(() => import("src/views/pages/Services")),
  },
  {
    exact: true,
    path: "/services-list",
    component: lazy(() => import("src/views/pages/Services/ServicesList")),
  },
  {
    exact: true,
    path: "/home-visits",
    component: lazy(() => import("src/views/pages/Services/HomeVisits")),
  },
  {
    exact: true,
    path: "/telephonic-consultation",
    component: lazy(() =>
      import("src/views/pages/Services/TelephonicConsultation")
    ),
  },
  {
    exact: true,
    path: "/practitioner-details",
    component: lazy(() =>
      import("src/views/pages/Services/PractitionerDetails")
    ),
  },
  {
    exact: true,
    path: "/practitioner-message-call",
    component: lazy(() =>
      import("src/views/pages/Services/PractitionerMessageCall")
    ),
  },
  {
    exact: true,
    path: "/book-practitioner",
    component: lazy(() => import("src/views/pages/Services/BookPractitioner")),
  },
  {
    exact: true,
    path: "/product-details",
    component: lazy(() => import("src/views/pages/Products/ProductDetails")),
  },
  {
    exact: true,
    path: "/products",
    component: lazy(() => import("src/views/pages/Products")),
  },
  {
    exact: true,
    path: "/product-categories",
    component: lazy(() => import("src/views/pages/Products/ProductCategories")),
  },
  {
    exact: true,
    path: "/confirm-address",
    guard: true,
    component: lazy(() => import("src/views/pages/Services/ConfirmAddress")),
  },
  {
    exact: true,
    path: "/appointment-address",
    guard: true,
    component: lazy(() =>
      import("src/views/pages/Services/AppointmentAddress")
    ),
  },
  {
    exact: true,
    path: "/medicals",
    guard: true,
    component: lazy(() => import("src/views/pages/Medicals")),
  },
  {
    exact: true,
    path: "/personal-details",
    guard: true,
    component: lazy(() => import("src/views/pages/Medicals/PersonalDetails")),
  },
  {
    exact: true,
    path: "/new-vaccines",
    guard: true,
    component: lazy(() => import("src/views/pages/Medicals/NewVaccines")),
  },
  {
    exact: true,
    path: "/allergy",
    guard: true,
    component: lazy(() => import("src/views/pages/Medicals/Allergy")),
  },
  {
    exact: true,
    path: "/drug-prescription",
    guard: true,
    component: lazy(() => import("src/views/pages/Medicals/DrugPrescription")),
  },
  {
    exact: true,
    path: "/blood-pressure",
    guard: true,
    component: lazy(() => import("src/views/pages/Medicals/BloodPressure")),
  },
  {
    exact: true,
    path: "/doctors",
    guard: true,
    component: lazy(() => import("src/views/pages/Medicals/Doctors")),
  },
  {
    exact: true,
    path: "/glucose",
    guard: true,
    component: lazy(() => import("src/views/pages/Medicals/Glucose")),
  },
  {
    exact: true,
    path: "/lab-test",
    guard: true,
    component: lazy(() => import("src/views/pages/Medicals/LabTest")),
  },
  {
    exact: true,
    path: "/examination",
    guard: true,
    component: lazy(() => import("src/views/pages/Medicals/Examination")),
  },
  {
    exact: true,
    path: "/documents",
    guard: true,
    component: lazy(() => import("src/views/pages/Medicals/Documents")),
  },
  {
    exact: true,
    path: "/share-medical-details",
    guard: true,
    component: lazy(() =>
      import("src/views/pages/Medicals/ShareMedicalDetails")
    ),
  },
  {
    exact: true,
    path: "/bookings",
    guard: true,
    component: lazy(() => import("src/views/pages/Bookings")),
  },
  {
    exact: true,
    path: "/cancellation-policy",
    component: lazy(() => import("src/views/pages/CancellationPolicy")),
  },
  {
    exact: true,
    path: "/past-purchases",
    guard: true,
    component: lazy(() => import("src/views/pages/PastPurchases")),
  },
  {
    exact: true,
    path: "/add-more-location",
    guard: true,
    component: lazy(() => import("src/views/pages/MyProfile/AddMoreLocation")),
  },
  {
    exact: true,
    path: "/loyalty-points",
    guard: true,
    component: lazy(() => import("src/views/pages/LoyaltyPoints")),
  },
  {
    exact: true,
    path: "/proceed-to-pay",
    guard: true,
    component: lazy(() => import("src/views/pages/payment/ProceedToPay")),
  },
  {
    exact: true,
    path: "/booking-payment",
    guard: true,
    component: lazy(() => import("src/views/pages/payment/BookingPayment")),
  },
  {
    exact: true,
    path: "/track-practitioner",
    guard: true,
    component: lazy(() => import("src/views/pages/TrackPractitioner")),
  },
  {
    exact: true,
    path: "/track",
    guard: true,
    component: lazy(() => import("src/views/pages/Track")),
  },
  {
    exact: true,
    path: "/messages",
    guard: true,
    component: lazy(() => import("src/views/pages/Messages")),
  },
  {
    exact: true,
    path: "/favourites",
    guard: true,
    component: lazy(() => import("src/views/pages/Favourites")),
  },
  {
    exact: true,
    path: "/cart",
    guard: true,
    component: lazy(() => import("src/views/pages/MyCart")),
  },
  {
    exact: true,
    path: "/my-profile",
    guard: true,
    component: lazy(() => import("src/views/pages/MyProfile")),
  },
  {
    exact: true,
    path: "/mobile-apps",
    component: lazy(() => import("src/views/pages/MobileApps")),
  },
  {
    exact: true,
    path: "/privacy-policy",
    component: lazy(() => import("src/views/pages/PrivacyPolicy")),
  },
  {
    exact: true,
    path: "/faqs",
    component: lazy(() => import("src/views/pages/FAQs")),
  },
  {
    exact: true,
    path: "/terms-conditions",
    component: lazy(() => import("src/views/pages/TermsConditions")),
  },
  {
    exact: true,
    path: "/about-alafiame",
    component: lazy(() => import("src/views/pages/AboutAlafiame")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    path: "/practitioner",
    practitioner: true,
    routes: [
      {
        exact: true,
        path: "/practitioner",
        practitioner: true,
        component: lazy(() => import("src/views/pages/Practitioner")),
      },
      // {
      //     exact: true,
      //     path: '/practitioner/privacy-policy',
      //     component: lazy(() => import('src/views/pages/Practitioner/PrivacyPolicy'))
      // },
      // {
      //     exact: true,
      //     path: '/practitioner/terms-conditions',
      //     component: lazy(() => import('src/views/pages/Practitioner/TermsConditions'))
      // },
      {
        exact: true,
        path: "/practitioner/personal-details",
        practitioner: true,
        component: lazy(() =>
          import("src/views/pages/Practitioner/PersonalDetails")
        ),
      },
      {
        exact: true,
        path: "/practitioner/tutorial-and-options",
        practitioner: true,
        component: lazy(() =>
          import("src/views/pages/Practitioner/TutorialOptions")
        ),
      },
      {
        exact: true,
        path: "/practitioner/location-and-qualifications",
        practitioner: true,
        component: lazy(() =>
          import("src/views/pages/Practitioner/LocationAndQualifications")
        ),
      },
      {
        exact: true,
        path: "/practitioner/qualification-details",
        practitioner: true,
        component: lazy(() =>
          import("src/views/pages/Practitioner/QualificationDetails")
        ),
      },
      {
        exact: true,
        path: "/practitioner/request-sent-successfully",
        practitioner: true,
        component: lazy(() =>
          import("src/views/pages/Practitioner/RequestSentSuccessfully")
        ),
      },
      {
        exact: true,
        path: "/practitioner/home",
        practitioner: true,
        guard: true,
        component: lazy(() => import("src/views/pages/Practitioner/Home")),
      },
      {
        exact: true,
        path: "/practitioner/requests",
        practitioner: true,
        guard: true,
        component: lazy(() => import("src/views/pages/Practitioner/Requests")),
      },
      {
        exact: true,
        path: "/practitioner/notification",
        practitioner: true,
        guard: true,
        component: lazy(() =>
          import("src/views/pages/Practitioner/Notification")
        ),
      },
      {
        exact: true,
        path: "/practitioner/bookings",
        practitioner: true,
        guard: true,
        component: lazy(() => import("src/views/pages/Practitioner/Bookings")),
      },
      {
        exact: true,
        path: "/practitioner/on-way-tracking",
        practitioner: true,
        guard: true,
        component: lazy(() =>
          import("src/views/pages/Practitioner/OnWayTracking")
        ),
      },
      // {
      //     exact: true,
      //     path: '/practitioner/start-service',
      //     practitioner:true,
      //     guard: true,
      //     component: lazy(() => import('src/views/pages/Practitioner/StartService'))
      // },
      {
        exact: true,
        path: "/practitioner/earnings",
        practitioner: true,
        guard: true,
        component: lazy(() => import("src/views/pages/Practitioner/Earnings")),
      },
      {
        exact: true,
        path: "/practitioner/my-profile",
        practitioner: true,
        guard: true,
        component: lazy(() => import("src/views/pages/Practitioner/MyProfile")),
      },
      {
        exact: true,
        path: "/practitioner/add-more-location",
        practitioner: true,
        guard: true,
        component: lazy(() =>
          import("src/views/pages/Practitioner/AddMoreLocation")
        ),
      },
      {
        exact: true,
        path: "/practitioner/checkout-customer-app",
        practitioner: true,
        guard: true,
        component: lazy(() =>
          import("src/views/pages/Practitioner/CheckoutCustomerApp")
        ),
      },
      {
        exact: true,
        path: "/practitioner/messages",
        practitioner: true,
        guard: true,
        component: lazy(() => import("src/views/pages/Practitioner/Messages")),
      },
      {
        exact: true,
        path: "/practitioner/change-password",
        practitioner: true,
        guard: true,
        component: lazy(() =>
          import("src/views/pages/Practitioner/ChangePassword")
        ),
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
